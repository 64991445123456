<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.warehouse-wise-product-storage-report') }}</h4>
      </template>
      <template v-slot:body>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row v-if="authUser.warehouse_id == null">
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationtList"
                id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            :label="$t('warehouse_report.division')"
            label-for="division_id"
            >
              <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionList"
                  id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            :label="$t('warehouse_report.district')"
            label-for="district_id"
            >
              <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_report.upazilla')"
                  label-for="upazilla_id"
              >
                <b-form-select
                    plain
                    v-model="search.upazilla_id"
                    :options="upazilaList"
                    id="upazilla_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
             <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('globalTrans.union')"
                  label-for="union_id"
              >
                <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="authUser.warehouse_id == null" xs="12" sm="12" md="6" lg="4">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="commodity_group_id"
                :label="$t('warehouse_config.warehouse_type')"
                >
                <b-form-select
                plain
                v-model="search.warehouse_type_id"
                :options="warehouseTypeList"
                id="commodity_group_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  label-for="warehouse_id"
                  :label="$t('warehouse_information.warehouse_name')"
              >
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-if="!authUser.warehouse_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-else
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_config.fiscal_year')"
                  label-for="year"
              >
                <b-form-select
                    plain
                    v-model="search.year"
                    :options="fiscalList"
                    id="year"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Crop Name" vid="commodity_group_id" rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('warehouse_config.crop_type')"
                    label-for="commodity_group_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.crop_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.commodity_group_id"
                        :options="commodityGroupList"
                        id="commodity_group_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
            <!-- <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.from_date')"
                label-for="from_date"
            >
            <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group> -->
          <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Crop Name" vid="crop_id" rules="required|min_value:1">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_config.crop_name')"
                label-for="crop_id"
                slot-scope="{ valid, errors }"
                >
                 <template v-slot:label>
                      {{ $t('warehouse_config.crop_name')}} <span class="text-danger">*</span>
                    </template>
                <b-form-select
                    plain
                    v-model="search.crop_id"
                    :options="commodityNameList"
                    id="crop_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.submit')}}</b-button>
            <!-- <b-button type="button" class="float-left" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button> -->
          </b-col>
        </b-row>
         </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.warehouse-wise-product-storage-report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Warehouse Wise Product Storage Information Report Sheet"
                  name="warehouse-wise-product-storage-info.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
               <b-row v-show="!loading">
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.ware-received-and-delivery-report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.organization') }}: </strong> {{ getOrganizationName(search.org_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                  </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.fiscal_year') }}: </strong> {{ getFiscalYearName(search.fiscal_year_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.crop_type') }}: </strong> {{ getCropTypeName(search.commodity_group_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.crop_name') }}: </strong> {{ getCropName(search.crop_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="wareReportList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_id)="data">
                      {{ data.item.division_id }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      {{ data.item.warehouse_name }}
                    </template>
                      <template v-slot:cell(current_quantity)="data">
                          <span>{{ $n(data.item.current_quantity) }}</span>
                      </template>
                      <template v-slot:cell(total_farmer)="data">
                          <span>{{ $n(data.item.total_farmer) }}</span>
                      </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template #custom-foot="">
                        <b-tr>
                          <b-th class="text-right" colspan="7">
                            {{ $t('sitePreference.grossTotal') }}
                          </b-th>
                          <b-th class="text-right" >
                            {{ $n(totalQty) }}
                          </b-th>
                          <b-th class="text-right" >
                            {{ $n(totalFarmer) }}
                          </b-th>
                        </b-tr>
                      </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, warehouseWiseProductStorageReportApi } from '../../api/routes'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
// import { loggedInUser } from '@/Utils/common'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)
export default {
  name: 'UiDataTable',
  components: { ListReportHead },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        crop_id: 0,
        commodity_group_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        year: 0,
        from_date: '',
        to_date: ''
      },
      organization: {},
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      tempList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      commodityNameList: [],
      rows: [],
      totalQty: 0,
      totalFarmer: 0,
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      districtLoading: false
    }
  },
  computed: {
    excelColumn: function () {
      return {
        SL: 'serial',
        Division: 'division',
        District: 'district',
        Upazila: 'upazila',
        Warehouse: 'warehouse',
        'Crop Type': 'cropType',
        'Crop Name': 'cropName',
        'Current Quantity(Quintal)': 'currentQuantity',
        'Total Farmer': 'totalFarmer'
      }
    },
    excelColumnBn: function () {
      return {
        'ক্রমিক নং': 'serial',
        বিভাগ: 'division',
        জেলা: 'district',
        উপজেলা: 'upazila',
        গুদাম: 'warehouse',
        'শস্যের ধরণ': 'cropType',
        'শস্য নাম': 'cropName',
        'বর্তমান পরিমাণ(কুইন্টাল)': 'currentQuantity',
        'মোট কৃষক': 'totalFarmer'
      }
    },
    json_fields: function () {
      return this.currentLocale === 'bn' ? this.excelColumnBn : this.excelColumn
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.organization') + ' : ' + this.getOrganizationName(this.search.org_id) + ' ' + this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id)
      const secondRow = this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id) + ' ' + this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id)
      const thirdRow = this.$t('warehouse_config.fiscal_year') + ' : ' + this.getFiscalYearName(this.search.fiscal_year_id) + ' ' + this.$t('warehouse_config.crop_type') + ' : ' + this.getCropTypeName(this.search.commodity_group_id) + ' ' + this.$t('warehouse_config.crop_name') + ' : ' + this.getCropName(this.search.crop_id)
      return [this.$t('warehouse_report.warehouse-wise-product-storage-report'), '', firstRow, secondRow, thirdRow, '']
    },
    commodityGroupList: function () {
            return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
        },
    dataCustomizeExcel () {
      var serial = 0
      const customizeData = this.wareReportList.map(item => {
        serial += 1
        if (this.currentLocale === 'bn') {
          item.serial = this.$n(serial)
          item.division = item.division_name_bn
          item.district = item.district_name_bn
          item.upazila = item.upazilla_name_bn
          item.warehouse = item.warehouse_name_bn
          item.cropType = item.crop_type_name_bn
          item.cropName = item.crop_name_bn
          item.currentQuantity = this.$n(item.current_quantity)
          item.totalFarmer = this.$n(item.total_farmer)
        } else {
          item.serial = this.$n(serial)
          item.division = item.division_name
          item.district = item.district_name
          item.upazila = item.upazilla_name
          item.warehouse = item.warehouse_name
          item.cropType = item.crop_type_name
          item.cropName = item.crop_name
          item.currentQuantity = this.$n(item.current_quantity)
          item.totalFarmer = this.$n(item.total_farmer)
        }
        return Object.assign({}, item)
      })
      return customizeData
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
      return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('warehouse_report.division'), class: 'text-left' },
        { label: this.$t('warehouse_report.district'), class: 'text-left' },
        { label: this.$t('warehouse_report.upazilla'), class: 'text-left' },
        { label: this.$t('warehouse_report.warehouse_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.crop_type'), class: 'text-left' },
        { label: this.$t('warehouse_report.crop_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.current_quantity'), class: 'text-right' },
        { label: this.$t('warehouse_report.total_farmer'), class: 'text-right' }
      ]

      let keys = []

      if (this.currentLocale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'crop_type_name_bn' },
          { key: 'crop_name_bn' },
          { key: 'current_quantity' },
          { key: 'total_farmer' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'warehouse_name' },
          { key: 'crop_type_name' },
          { key: 'crop_name' },
          { key: 'current_quantity' },
          { key: 'total_farmer' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.org_id': function (newVal, oldVal) {
      this.organization = this.organizationData(newVal)
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    },
    'search.commodity_group_id': function (newVal, oldVal) {
        this.commodityNameList = this.getcommodityList(newVal)
        }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    searchData () {
      // if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.organizationData()
        this.showData = true
        this.showHeading = true
      // }
    },
    getcommodityList (Id = null) {
        return this.$store.state.warehouse.commodityNameList.filter(item => item.commodity_group_id === Id)
    },
    async organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      await RestApi.getData(warehouseServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.wareReportList = []
      this.totalQty = 0
      this.totalFarmer = 0
      await RestApi.getData(warehouseServiceBaseUrl, warehouseWiseProductStorageReportApi, this.search).then(response => {
        if (response.success) {
          this.dataList(response)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    },
    dataList (data) {
      const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const commodityGroupList = this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
      const commodityNameList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
      let qty = 0
     let fr = 0
      const wareReportData = data.data.map(item => {
          qty += item.current_quantity
          fr += item.total_farmer
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const commodityGroupObject = commodityGroupList.find(commodityGroup => commodityGroup.value === item.crop_type_id)
        const commodityNameObject = commodityNameList.find(commodityName => commodityName.value === item.crop_id)
        const divisionData = {
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
          upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
        }
        const commodityGroupData = {
          crop_type_name: commodityGroupObject !== undefined ? commodityGroupObject.text_en : '',
          crop_type_name_bn: commodityGroupObject !== undefined ? commodityGroupObject.text_bn : ''
        }
        const commodityNameData = {
          crop_name: commodityNameObject !== undefined ? commodityNameObject.text_en : '',
          crop_name_bn: commodityNameObject !== undefined ? commodityNameObject.text_bn : ''
        }
        return Object.assign({}, item, divisionData, districtData, upazillaData, commodityGroupData, commodityNameData)
      })
      this.wareReportList = wareReportData
      this.totalQty = qty
      this.totalFarmer = fr
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObjectTemp = response.filter(document => document.region_id === regionId)
          const dataObject = dataObjectTemp.filter(item => item.status === 0)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
        this.districtLoading = false
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.division'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.upazilla'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.warehouse_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.current_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_farmer'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
    const labels1 = [
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('sitePreference.grossTotal'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$n(this.totalQty), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$n(this.totalFarmer), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      rowData.push(labels1)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Wise Product Storage Information Report' : 'গুদাম অনুযায়ী পণ্য সংরক্ষণ পদ্ধতি তথ্য রিপোর্ট'
      const columnWids = ['7%', '10%', '10%', '10%', '15%', '12%', '12%', '10%', '10%']
      const header1 = [
        { text: `${this.$t('globalTrans.organization')} : ${this.getOrganizationName(this.search.org_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header3 = [
        { text: `${this.$t('warehouse_config.fiscal_year')} : ${this.getFiscalYearName(this.search.fiscal_year_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.crop_type')} : ${this.getCropTypeName(this.search.commodity_group_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.crop_name')} : ${this.getCropName(this.search.crop_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2, header3]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'warehouse-wise-product-storage-info')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'division_name' : 'division_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'district_name' : 'district_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'upazilla_name' : 'upazilla_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'warehouse_name' : 'warehouse_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'current_quantity' },
          { key: 'total_farmer' }
        ]
      var serial = 0
      const listData = this.wareReportList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (['current_quantity', 'total_farmer'].includes(keyItem.key)) {
              return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'serial_no') {
              return { text: this.$n(serial) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFiscalYearName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.fiscalList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getOrganizationName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.organizationtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getCropTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.commodityGroupList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getCropName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.commodityNameList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
